/* Treatment Carousel Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {

	if ($(".treatment-carousel-wrapper .slick-treatment-image")) {
		$(".treatment-carousel-wrapper .slick-treatment-image").on('init', function() {
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 7500,
			speed: 1500,
			mobileFirst: true,
			pauseOnDotsHover: true,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: false,
			dots: false,
			centerMode: true,
			centerPadding: '0',
			asNavFor: '.slick-treatment-content',
			focusOnSelect: true,
			rows: 0,
			fade: true,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						fade: false,
						centerMode: true
					}
				}
			]
		});
	}

	if ($(".treatment-carousel-wrapper .slick-treatment-content")) {
		$(".treatment-carousel-wrapper .slick-treatment-content").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 7500,
			speed: 1500,
			mobileFirst: true,
			pauseOnDotsHover: true,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: true,
			appendArrows: '.treatment-carousel-navigation',
			prevArrow: '<span><i class="far fa-arrow-left"></i></span>',
			nextArrow: '<span><i class="far fa-arrow-right"></i></span>',
			dots: false,
			fade: true,
			rows: 0,
			adaptiveHeight: true,
			asNavFor: '.slick-treatment-image',
		});
	}

});
