/* Banner Content Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-content-wrapper .banner-map").length) {
		$(".banner-content-wrapper").addClass('has-map');
	}

	if ($(".banner-content-wrapper").length && $(".banner-content-slick .banner-slide").length > 1) {

		$(".banner-content-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			appendDots: '.banner-slide-navigation',
			fade: true,
			rows: 0,
			adaptiveHeight: true,
			customPaging: function(slider, i) {
				return '<button>' + ('0' + (i + 1)).slice(-2) + '</button>';
			}
		});

		// matchContentHeight(".banner-content-wrapper .banner-content-slick .banner-slide .content-wrapper .col-content");

		// if ($(".banner-content-wrapper .banner-map").length) {
		// 	const bannerMapController = new ScrollMagic.Controller();
		// 	const bannerMapScene = new ScrollMagic.Scene({
		// 		triggerHook: "onEnter",
		// 		duration: "200%"
		// 	})
		// 	.setTween(".banner-content-wrapper .banner-map", {
		// 		y: "40%"
		// 	});
		// 	bannerMapScene.addTo(bannerMapController);
		// }

		// if ($(".banner-content-wrapper .banner-background .background").length) {
		// 	const bannerContentController = new ScrollMagic.Controller();
		// 	const bannerContentScene = new ScrollMagic.Scene({
		// 		triggerHook: "onEnter",
		// 		duration: "200%"
		// 	})
		// 	.setTween(".banner-content-wrapper .banner-background .background", {
		// 		y: "40%"
		// 	});
		// 	bannerContentScene.addTo(bannerContentController);
		// }
	}
});



window.matchContentHeight = function(element) {
	$(window).on('load rotate resize', function() {
		if ($(window).width() < 992) {
			let match;
			match = $(element);
			match.height('auto');
		}
		if ($(window).width() > 991) {
			let maxHeight = 0;
			let match;
			match = $(element);
			match.height('auto');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
			});
			match.height(maxHeight);
		}
	});
}

