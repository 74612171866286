/* Quote With Background Block JS File */
$(document).ready(function() {
	if ($(".quote-with-background-wrapper").length) {
		$(window).on('load rotate resize', function() {
			const quoteWithBackgroundHeight = $("#quote-with-background-full-height").height();
			let treatmentCarouselHeight = 0;
			let aboutBoxHeight = 0;
			if ($(".treatment-carousel-wrapper").length) { treatmentCarouselHeight = $("#treatment-carousel-half-height").height(); }
			if ($(".about-box-wrapper").length) { aboutBoxHeight = $("#about-box-half-height").height(); }
			const totalHeight = quoteWithBackgroundHeight + treatmentCarouselHeight + aboutBoxHeight;
			$(".quote-with-background-wrapper #background-gradient").css({
				"height" : totalHeight,
				"top" : (treatmentCarouselHeight * -1),
			});
		});
	}
});
