/* Post Grid Block JS File */
import select2 from 'select2';
$(document).ready(function() {
	if ($(".post-grid-block").length) {

		const baseUrl = location.protocol + '//' + location.hostname + "/" + $(".post-grid-wrapper").attr('data-base') + "/";

		$(".filter-value").select2();

		$(window).on('load resize rotate', function() {
			if ($('.post-grid-wrapper .post-wrapper .section-body').length) {
				matchBlogHeight('.post-grid-wrapper .post-wrapper .section-body');
			}
		})

		function postGridFormData() {
			let formData = {};
			$.each($('#post-grid-form').serializeArray(), function() {
				formData[this.name] = this.value;
			});
			return formData;
		}

		function postGridCreateUrl(paged) {
			let formData = postGridFormData();
			const category = typeof formData.category !== "undefined" && formData.category !== 'all' ? formData.category + '/' : '';
			const year = typeof formData.year !== "undefined" && formData.year !== 'all' ? formData.year + '/' : '';
			const monthnum = typeof formData.monthnum !== "undefined" && formData.monthnum !== 'all' ? formData.monthnum + '/' : '';
			const page = typeof paged !== "undefined" ? 'page/' + paged : '';
			return baseUrl + category + year + monthnum + page;
		}

		function postGridPushUrl(paged) {
			if (window.history.replaceState) {
				let formData = postGridFormData();
				window.history.pushState({
					formData: formData,
					paged: paged
				}, null, postGridCreateUrl(paged));
			}
		}

		function postGridLoad(paged) {
			postGridPushUrl(paged);
			$("#post-grid-loader").addClass('active');
			$("#post-grid-load").load(coreData.ajaxurl, {
				action: 'load_post_grid',
				formData: postGridFormData(),
				postGridData: $("#post-grid-load").attr('data-pgd'),
				paged: paged
			}, function(response) {
				$("#post-grid-loader").removeClass('active');
				lazyLoadInstance.update();
				$(window).trigger('resize');
			});
		}

		$("#post-grid-form .filter-value").on('select2:select', function() {
			postGridLoad();
		});

		$("#post-grid-load").on('click', "#pagination .paginate-trigger", function() {
			const paged = $(this).attr('data-paged');
			$(".post-grid-wrapper").attr('data-page', paged);
			postGridLoad(paged);
			$("body, html").animate({
				scrollTop: $('.section-filter').offset().top - 200
			}, 750);
		});
	}
});



window.matchBlogHeight = function(element) {
	$(window).on('load rotate resize', function() {
		if ($(window).width() < 768) {
			$(element).height('auto');
		} else if ($(window).width() > 767) {
			let maxHeight = 0;
			let match;
			match = $(element);
			match.height('auto');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
			});
			match.height(maxHeight);
		}
	});
}
