/* Content and Blog Block JS File */
$(document).ready(function() {
	if ($('.content-and-blog-wrapper').length > 0) {
		let blog_element = $('.content-and-blog-wrapper .section-inner .content-blog-height');
		$(window).on('load rotate resize', function() {
			if ($(window).width() < 992) {
				let match = $(blog_element);
				match.height('auto');
			} else if ($(window).width() > 991) {
				let maxHeight = 0;
				let match = $(blog_element);
				match.height('auto');
				match.each(function() {
					if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
				});
				match.height(maxHeight);
			}
		});
	}
});
