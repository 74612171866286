/* Banner Home Component JS File */
import slick from 'slick-carousel';

$(document).ready(function() {
	if ($(".banner-home-wrapper").length) {

		$(".banner-left-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: false,
			fade: true,
			asNavFor: '.banner-right-slick',
			rows: 0,
			adaptiveHeight: true,
		});

		$(".banner-right-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			appendDots: '.banner-slide-navigation',
			fade: true,
			asNavFor: '.banner-left-slick',
			rows: 0,
			adaptiveHeight: true,
			customPaging: function(slider, i) {
				return '<button><span>' + ('0' + (i + 1)).slice(-2) + '</span></button>';
			}
		});

		$(".slick-information-slides").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: true,
			dots: false,
			fade: false,
			appendArrows: '.slick-information-navigation',
			prevArrow: '<div class="prev-arrow"><i class="fas fa-caret-left"></i></div>',
			nextArrow: '<div class="next-arrow"><i class="fas fa-caret-right"></i></div>',
			rows: 0
		});

		$(window).on('load resize rotate', function() {
			matchHeight(".banner-home-wrapper .section-banner-right .section-right .banner-right-slick .banner-right-slide .banner-content .content-wrapper");
		});

	}
});
