/* Tabbed Content Block JS File */
// import tab from 'bootstrap';
$(document).ready(function() {
	// $('.tabbed-content-tab').tab();
	if ($('.tabbed-content-block').length > 0) {
		$(window).on('load resize rotate', function() {
			if ($(window).width() < 768) {
				$('.tabbed-content-wrapper .tab-pane').height('auto');
				$('.tab-height').height($('.tabbed-content-wrapper .tab-pane[id^="tabbed-content-tab-1"].active').height());
			} else if ($(window).width() > 767) {
				matchHeight('.tabbed-content-wrapper .tab-pane');
			}
		});

			$('.tab-height').height($('.tabbed-content-wrapper .tab-pane[id^="tabbed-content-tab-1"].active').height());
			$('a[data-toggle="tab"]').on('show.bs.tab', function() {
				$('.tab-height').height($($(this).attr('href')).height());
			});

	}

});
