/* Map and Contact Details Block JS File */
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
$(document).ready(function() {
	if ($('.map-and-contact-details-wrapper .map-parallax').length) {
		const mapAndContactBackgroundTween = TweenMax.fromTo(".map-and-contact-details-wrapper .map-parallax", 1, { y: '-25%' }, { y: '20%' });
		const mapAndContactBackgroundController = new ScrollMagic.Controller();
		const mapAndContactBackgroundScene = new ScrollMagic.Scene({
			triggerElement: ".map-and-contact-details-wrapper .map-parallax",
			triggerHook: "onEnter",
			duration: "300%"
		}).setTween(mapAndContactBackgroundTween);

		mapAndContactBackgroundScene.addTo(mapAndContactBackgroundController);
	}
});
