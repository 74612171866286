/* Banner Video Hub Component JS File */

import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-blog-wrapper").length) {

		// if ($(".banner-blog-wrapper .banner-background .background").length) {
		// 	const bannerBlogController = new ScrollMagic.Controller();
		// 	const bannerBlogScene = new ScrollMagic.Scene({
		// 		triggerHook: "onEnter",
		// 		duration: "200%"
		// 	})
		// 	.setTween(".banner-blog-wrapper .banner-background .background", {
		// 		y: "40%"
		// 	});
		// 	bannerBlogScene.addTo(bannerBlogController);
		// }
	}
});

